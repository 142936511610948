import axios from 'axios';
import { getTokenFromCryptedStorage } from './utils';

const authClient = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json',
});

authClient.interceptors.request.use((request) => {
  const token = getTokenFromCryptedStorage();
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

authClient.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const isNotFoundError = error.status === 404;

    if (isNotFoundError) {
      return Promise.reject({ message: 'Network error' });
    }

    const isAuthError = [401, 403].includes(error.response.status);
    const isClientError =
      error.response.status >= 400 && error.response.status < 500;
    const isServerError = error.response.status >= 500;

    if (isAuthError) {
      return Promise.reject({
        code: 'authentication_error',
        message: 'Authentication error',
      });
    } else if (isClientError) {
      return Promise.reject({
        code: error.response.data.errors[0].code,
        message: error.response.data.errors[0].detail,
      });
    } else if (isServerError) {
      return Promise.reject({
        code: 'server_error',
        message: 'El servidor no responde, regresa más tarde',
      });
    } else {
      return Promise.reject({
        code: 'network_error',
        message: 'Network error',
      });
    }
  },
);

export default authClient;
